import React from "react";
import bgContImg from "./assets/bg_cont.png";
import bg1Img from "./assets/bg1.jpg";
import animatedImg from "./assets/animated2.gif";
import max1Img from "./assets/max1crop.jpg";
import max2Img from "./assets/max2.jpeg";
import max3Img from "./assets/max3.jpeg";
import max4Img from "./assets/max4.jpeg";
import max5Img from "./assets/max5.jpeg";
import max6Img from "./assets/max6.jpeg";

// import phoneImage from "./path-to-phone-image.png";
import appleStoreLogo from "./assets/apple-appstore.png";
import playStoreLogo from "./assets/android_badge_black.png";
import PhoneMockup from "./PhoneMockup";
import TextCard from "./TextCard";
import Footer from "./Footer";

export default function LandingPage2() {
  return (
    <div>
      <div
        className="relative flex min-h-screen flex-col items-center bg-cover bg-center bg-no-repeat text-center"
        style={{
          backgroundImage: `url(${animatedImg})`,
        }}
      >
        <nav className="container mx-auto flex items-center justify-between py-4">
          <div className="text-2xl font-bold tracking-wider text-white">
            AI FANTASY
          </div>
        </nav>

        {/* Main Content */}
        <div className="container relative z-10 mx-auto flex flex-col items-center px-4 py-8 text-white lg:flex-row lg:items-start lg:justify-center lg:py-16">
          {/* Phone Mockup */}
          <div className="flex-shrink-0">
            <PhoneMockup
              img={max5Img}
              content={
                <div className="absolute inset-0 flex flex-col items-center justify-center text-white">
                  <h2 className="mb-4 text-2xl font-bold">Become</h2>
                  <p className="text-sm">
                    Transform into your favorite character
                  </p>
                </div>
              }
            />
          </div>

          {/* Headlines */}
          <div className="mt-8 text-left lg:ml-8 lg:mt-0">
            <h1 className="mt-72 inline-block bg-black p-3 text-3xl font-bold text-white md:text-5xl">
              Chat with AI characters
            </h1>
            <h1 className="mt-4 inline-block bg-black p-3 text-3xl font-bold text-white md:text-5xl">
              Experience the magic of anime like never before
            </h1>
          </div>

          <div>
            <a href="https://apps.apple.com/us/app/id6464451134">
              <img
                src={appleStoreLogo}
                alt="Download on Apple Store"
                style={{ width: 400, marginBottom: 8 }}
              />
            </a>
            <a href="https://aifantasy.com/Fantasia_1.21.5.703.apk">
              <img
                src={playStoreLogo}
                alt="Download for Android"
                style={{ width: 400, marginBottom: 8 }}
              />
            </a>
          </div>
        </div>
      </div>

      <div
        className="relative flex min-h-screen flex-col items-center bg-cover bg-center bg-no-repeat text-center"
        style={{
          backgroundImage: `url(${bgContImg})`,
        }}
      >
        <div className="relative z-30" style={{ height: 200 }}>
          <h1 className="mt-8 bg-black p-3 text-3xl font-bold text-white md:text-5xl">
            Customize your own avatar and livestream without showing your face.
          </h1>
          <h1 className="mt-8 bg-black p-3 text-3xl font-bold text-white md:text-5xl">
            Advanced AI for Realistic Interaction
          </h1>
        </div>

        <div className="flex justify-center gap-96">
          <PhoneMockup
            img={max1Img}
            content={
              <div className="absolute inset-0 flex flex-col items-center justify-center text-white">
                {/* <h2 className="mb-4 text-2xl font-bold">Become </h2>
                <p className="text-sm">
                  Transform into your favorite character
                </p> */}
                <h1
                  className="relative z-20 bg-black p-10 text-3xl font-bold text-white md:text-5xl"
                  style={{ left: -20, top: 250 }}
                >
                  <div>ANIME</div>
                  <div>FANS</div>
                </h1>
              </div>
            }
          />
          <PhoneMockup
            img={max2Img}
            content={
              <div className="absolute inset-0 flex flex-col items-center justify-center text-white">
                <h1
                  className="relative z-20 bg-black p-5 text-3xl font-bold text-white md:text-5xl"
                  style={{ left: 0, top: 250 }}
                >
                  <div>GAMER</div>
                  <div>COMMUNITY</div>
                </h1>
              </div>
            }
          />
        </div>
      </div>

      <div
        className="bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${bgContImg})`,
        }}
      >
        <h1
          className="relative z-20 bg-black p-10 text-center text-3xl font-bold text-white md:text-5xl"
          style={{}}
        >
          EARN WHILE YOU PLAY
        </h1>
        <div className="relative flex flex-row items-center justify-center gap-12 bg-cover bg-center bg-no-repeat p-12 text-center">
          <TextCard
            title="Create AI Fantasies with a bonus"
            number="1"
            variant="funky"
          />

          <TextCard
            title="New user gets welcome bonus"
            number="2"
            variant="pastel"
          />
          <TextCard
            title="Earn a referral bonus with each signup"
            number="3"
            variant="neon"
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}
