import React from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";

const Footer = () => {
  return (
    <footer className="bg-gray-900 py-4 text-gray-400">
      <div className="container mx-auto flex flex-col items-center">
        <ul className="mb-4 flex space-x-6">
          <li>
            <a
              href="https://www.tiktok.com/@ai_fantasy_app"
              target="_blank"
              className="fa-brands fa-tiktok fa-crunchbase text-2xl text-gray-400 transition-colors duration-200 hover:text-white"
              aria-label="Tiktok"
            ></a>
          </li>
          <li>
            <a
              href="https://twitter.com/AIFantasy_app"
              target="_blank"
              className="fa-brands fa-twitter text-2xl text-gray-400 transition-colors duration-200 hover:text-white"
              aria-label="Twitter"
            ></a>
          </li>
          <li>
            <a
              href="https://www.facebook.com/profile.php?id=61551539514545&amp;is_tour_completed=true"
              target="_blank"
              className="fa-brands fa-facebook-f text-2xl text-gray-400 transition-colors duration-200 hover:text-white"
              aria-label="Facebook"
            ></a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/ai_fantasy_app/"
              target="_blank"
              className="fa-brands fa-instagram text-2xl text-gray-400 transition-colors duration-200 hover:text-white"
              aria-label="Instagram"
            ></a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/company/ai-fantasy/"
              target="_blank"
              className="fa-brands fa-linkedin-in text-2xl text-gray-400 transition-colors duration-200 hover:text-white"
              aria-label="LinkedIn"
            ></a>
          </li>
          <li>
            <a
              href="https://www.crunchbase.com/organization/ai-fantasy/"
              target="_blank"
              className="text-gray-400 transition-colors duration-200 hover:text-white"
              aria-label="Crunchbase"
            >
              Crunchbase
            </a>
          </li>
        </ul>
        <address className="mb-4 text-center text-sm not-italic text-gray-500">
          AI Fantasy Inc. <br />
          2261 Market Street STE 22062 <br />
          San Francisco, CA 94114
        </address>

        <p className="text-sm text-gray-500">
          &copy; 2023-2024 AI Fantasy Inc.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
