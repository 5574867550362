const PhoneMockup = ({ img, content }) => {
  return (
    <div
      className="relative h-[800px] w-[400px] overflow-hidden rounded-[3rem] border-8 border-black bg-black p-4 shadow-2xl"
      style={{
        backgroundImage: `url(${img})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      {content}
    </div>
  );
};

export default PhoneMockup;
