import { useEffect, useState } from "react";

export default function TestPageiOS() {
  //   var chrome_url =
  //     "googlechrome://" +
  //     window.location.href.replace("https://", "").replace("http://", "");
  //   window.location.href = chrome_url;
  // }, []);
  const [ins, setIns] = useState("");
  const [platform, setPlatform] = useState("undetected");

  useEffect(() => {
    // Check if the user is on iOS or Android
    const isInstagram = navigator.userAgent.includes("Instagram");
    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    const isAndroid = /Android/i.test(navigator.userAgent);

    if (isInstagram) {
      setIns("Is Instagram");
    } else {
      setIns("Not Instagram");
    }

    if (isIOS) {
      setPlatform("iOS");
    } else if (isAndroid) {
      setPlatform("Android");
    }

    const currentURL = window.location.href.replace(/^https?:\/\//, ""); // Removes 'http://' or 'https://'
    const chromeURL = `googlechrome://navigate?url=${currentURL}`;
    console.log("navigating to ", chromeURL);
    window.location.href = chromeURL;
  }, []);

  return (
    <div>
      <div>Version 3</div>
      <div>{ins}</div>
      {/* <div>Detected platform: {platform}</div> */}
    </div>
  );
}
