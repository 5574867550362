import React from "react";

function TextCard({ title, number, variant }) {
  // Define different styles for each variant
  const styles = {
    default: {
      background: "bg-gradient-to-r from-purple-500 to-indigo-500",
      shadow: "shadow-xl",
      textColor: "text-white",
      hover: "hover:-translate-y-2",
    },
    funky: {
      background: "bg-gradient-to-r from-green-400 to-blue-500",
      shadow: "shadow-2xl",
      textColor: "text-yellow-50",
      hover: "hover:-rotate-3 hover:scale-105",
    },
    neon: {
      background: "bg-gradient-to-r from-pink-500 to-orange-500",
      shadow: "shadow-2xl shadow-pink-500/50",
      textColor: "text-white",
      hover: "hover:skew-y-2 hover:scale-110",
    },
    pastel: {
      background: "bg-gradient-to-r from-yellow-300 to-pink-300",
      shadow: "shadow-lg shadow-yellow-500/40",
      textColor: "text-gray-800",
      hover: "hover:translate-x-2 hover:scale-95",
    },
  };

  // Apply styles based on the selected variant, defaulting to "default"
  const selectedStyle = styles[variant] || styles.default;

  return (
    <div
      className={`relative flex h-40 w-64 flex-col items-center justify-center ${selectedStyle.background} ${selectedStyle.shadow} transform rounded-2xl p-4 ${selectedStyle.hover} transition-transform duration-300`}
    >
      {/* Decorative Sparkle Background */}
      <div className="pointer-events-none absolute inset-0 rounded-2xl bg-[radial-gradient(circle,rgba(255,255,255,0.2)_0%,transparent_70%)] opacity-40" />

      {/* Large number display with funky styling */}
      <div
        className={`text-5xl font-extrabold ${selectedStyle.textColor} mb-2 drop-shadow-lg`}
      >
        {number}
      </div>

      {/* Title with fixed height and playful color */}
      <div
        className={`text-lg font-semibold ${selectedStyle.textColor} flex h-12 items-center justify-center text-center`}
      >
        {title}
      </div>
    </div>
  );
}

export default TextCard;
