import { useEffect, useState } from "react";

export default function TestPageAndroid() {
  //   var chrome_url =
  //     "googlechrome://" +
  //     window.location.href.replace("https://", "").replace("http://", "");
  //   window.location.href = chrome_url;
  // }, []);
  const [ins, setIns] = useState("");
  const [platform, setPlatform] = useState("undetected");

  useEffect(() => {
    // Check if the user is on iOS or Android
    const isInstagram = navigator.userAgent.includes("Instagram");
    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    const isAndroid = /Android/i.test(navigator.userAgent);

    if (isInstagram) {
      setIns("Is Instagram");
    } else {
      setIns("Not Instagram");
    }

    if (isIOS) {
      setPlatform("iOS");
      const chromeURL = `googlechrome://${window.location.href.replace(/^https?:\/\//, "")}`;
      // const chromeURL = `googlechrome://${window.location.href}`;
      window.location.href = chromeURL;
    } else if (isAndroid) {
      setPlatform("Android");
      // Directly try to open in Chrome using Intent for Android
      // const intentURL = `intent://${window.location.href.replace(/^https?:\/\//, "")}#Intent;scheme=https;package=com.android.chrome;end`;
      // window.location.href = intentURL;
      window.location.href =
        "https://file.archat.us/cai/user_custom_avatar/1002339/f379379c-9f97-45de-bb8a-66d4b9198b40.jpeg";
    } else {
      setPlatform("desktop");
    }
  }, []);

  return (
    <div>
      <div>Version 18</div>
      <div>{ins}</div>
      <div>Detected platform: {platform}</div>
    </div>
  );
}
