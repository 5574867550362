import "./App.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import LandingPage2 from "./LandingPage2";
import TestPageiOS from "./TestPageiOS";
import TestPageAndroid from "./TestPageAndroid";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage2 />} />
        <Route path="/test_ios" element={<TestPageiOS />} />
        <Route path="/1" element={<TestPageiOS />} />
        <Route path="/test_android2" element={<TestPageAndroid />} />
        <Route path="/4" element={<TestPageAndroid />} />
        <Route path="/finish" element={<div>Finish</div>} />

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
